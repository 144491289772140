import React, { Suspense, useEffect, useState, useContext } from "react";
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Html, useProgress } from '@react-three/drei';
import Model0 from '../assets/0';
import Model1 from '../assets/1';
import Model2 from '../assets/2';
import Model3 from '../assets/3';
import Model4 from '../assets/4';
import Model5 from '../assets/5';
import Model6 from '../assets/6';
import Model7 from '../assets/7';
import Model8 from '../assets/8';
import Model9 from '../assets/9';
import Model10 from '../assets/10';
import Model11 from '../assets/11';
import Model12 from '../assets/12';
import Model13 from '../assets/13';
import Model14 from '../assets/14';
import Model15 from '../assets/15';
import Model16 from '../assets/16';
import Model17 from '../assets/17';
import Model18 from '../assets/18';
import Model19 from '../assets/19';
import Model20 from '../assets/20';
import Model21 from '../assets/21';
import Model22 from '../assets/22';
import Model23 from '../assets/23';
import Model24 from '../assets/24';
import Model25 from '../assets/25';
import Model26 from '../assets/26';
import Model27 from '../assets/27';
import Model28 from '../assets/28';
import Model29 from '../assets/29';
import Model30 from '../assets/30';
import Model31 from '../assets/31';
import Model32 from '../assets/32';
import Model33 from '../assets/33';
import Model34 from '../assets/34';
import Model35 from '../assets/35';
import Model36 from '../assets/36';
import Model37 from '../assets/37';
import Model38 from '../assets/38';
import Model39 from '../assets/39';
import Model40 from '../assets/40';
import Model41 from '../assets/41';
import Model42 from '../assets/42';
import Model43 from '../assets/43';
import Model44 from '../assets/44';
import Model45 from '../assets/45';
import Model46 from '../assets/46';
import Model47 from '../assets/47';
import Model48 from '../assets/48';
import Model49 from '../assets/49';

const Model = (props) => {
    const Loader = () => {
        const { progress } = useProgress();
        let p_male = progress.toFixed(2);
        return <Html><p className='item-loader'>{p_male}%</p></Html>
    };

    const setModel = () => {
        switch(props.id) {
            default:
            case "0":
                return <Model0 position={props.position} />;

            case "1":
                return <Model1 position={props.position} />;

            case "2":
                return <Model2 position={props.position} />;

            case "3":
                return <Model3 position={props.position} />;

            case "4":
                return <Model4 position={props.position} />;

            case "5":
                return <Model5 position={props.position} />;

            case "6":
                return <Model6 position={props.position} />;

            case "7":
                return <Model7 position={props.position} />;

            case "8":
                return <Model8 position={props.position} />;

            case "9":
                return <Model9 position={props.position} />;

            case "10":
                return <Model10 position={props.position} />;

            case "11":
                return <Model11 position={props.position} />;

            case "12":
                return <Model12 position={props.position} />;

            case "13":
                return <Model13 position={props.position} />;

            case "14":
                return <Model14 position={props.position} />;

            case "15":
                return <Model15 position={props.position} />;

            case "16":
                return <Model16 position={props.position} />;

            case "17":
                return <Model17 position={props.position} />;

            case "18":
                return <Model18 position={props.position} />;

            case "19":
                return <Model19 position={props.position} />;

            case "20":
                return <Model20 position={props.position} />;

            case "21":
                return <Model21 position={props.position} />;

            case "22":
                return <Model22 position={props.position} />;

            case "23":
                return <Model23 position={props.position} />;

            case "24":
                return <Model24 position={props.position} />;

            case "25":
                return <Model25 position={props.position} />;

            case "26":
                return <Model26 position={props.position} />;

            case "27":
                return <Model27 position={props.position} />;

            case "28":
                return <Model28 position={props.position} />;

            case "29":
                return <Model29 position={props.position} />;

            case "30":
                return <Model30 position={props.position} />;

            case "31":
                return <Model31 position={props.position} />;

            case "32":
                return <Model32 position={props.position} />;

            case "33":
                return <Model33 position={props.position} />;

            case "34":
                return <Model34 position={props.position} />;

            case "35":
                return <Model35 position={props.position} />;

            case "36":
                return <Model36 position={props.position} />;

            case "37":
                return <Model37 position={props.position} />;

            case "38":
                return <Model38 position={props.position} />;

            case "39":
                return <Model39 position={props.position} />;

            case "40":
                return <Model40 position={props.position} />;

            case "41":
                return <Model41 position={props.position} />;

            case "42":
                return <Model42 position={props.position} />;

            case "43":
                return <Model43 position={props.position} />;

            case "44":
                return <Model44 position={props.position} />;

            case "45":
                return <Model45 position={props.position} />;

            case "46":
                return <Model46 position={props.position} />;

            case "47":
                return <Model47 position={props.position} />;

            case "48":
                return <Model48 position={props.position} />;

            case "49":
                return <Model49 position={props.position} />;

        }
    }
    
    useEffect(() => {

    }, []);

    return (
    <>
        <Canvas
            camera={{ position: props.camera, fov: props.fov }}
            className="item-model-area"
            style={{
                backgroundColor: props.backgroundColor,
            }}
        >
            <ambientLight intensity={1.25} />
            <ambientLight intensity={0.1} />
            <directionalLight intensity={0.4} />
            <Suspense fallback={<Loader />}>
                { setModel() }
            </Suspense>
            <OrbitControls />
        </Canvas>
    </>
    );
};

export default Model;
