/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('assets/glb-file/46.glb')
  const { actions } = useAnimations(animations, group)

  useEffect(() => {
    //actions['jump'].play();
    //actions['walk'].play();
    //actions['tricks_2'].play();
    //actions['tricks_1'].play();
    actions['idle_and_push'].play();
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group name="RootNode0" scale={0.1}>
          <group name="geo1">
            <skinnedMesh name="skateboard_character2" geometry={nodes.skateboard_character2.geometry} material={nodes.skateboard_character2.material} skeleton={nodes.skateboard_character2.skeleton} />
          </group>
          <group name="skeletal3">
            <primitive object={nodes.root4} />
          </group>
        </group>
      </group>
    </group>
  )
}

//useGLTF.preload('assets/glb-file/46.glb')
