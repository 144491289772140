import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Routes, Route, Link, useParams, useLocation } from "react-router-dom";
import { Collapse, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Home from "./components/Home";
import Collection from "./components/Collection";
import Item from "./components/Item";
import Account from "./components/Account";
import ContractMetaMaskService from "./services/Contract-Metamask";
import ScrollToTop from "./ScrollToTop";

function App() {
  const [menuShow, setMenuShow] = useState(false);
  const handleCloseMenu = () => setMenuShow(false);
  const handleShowMenu = () => setMenuShow(true);
  const [connectWalletModalShow, setConnectWalletModalShow] = useState(false);

  const [currentAccount, setCurrentAccount] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [chainId, setChainId] = useState();
  const [chainName, setChainName] = useState();

  const showToast = (text) => {
    toast(text);
  }

  const connectMetaMask = async () => {
    let response = await ContractMetaMaskService.connect();
    let data = JSON.parse(response);
    if(data.installmetamask === false) {
      showToast("Please install MetaMask!");
      setConnectWalletModalShow(false);
    }
    else {
      setCurrentAccount(data.userAddress);
      setIsOwner(data.isOwner);
      setChainId(data.chainId);
      setChainName(data.chainName);
      setConnectWalletModalShow(false);
    }
  };

  const disconnectMetaMask = async () => {
    setCurrentAccount(undefined);
    setIsOwner(false);
    setChainId(undefined);
    setChainName(undefined);
  }

  return (
    <div className="App">
      <Navbar bg="white" variant="white" expand="lg" className="sticky-top app-topbar p-2"> 
        
        <Link to="/" className="navbar-brand">
          <FontAwesomeIcon icon={solid('cube')} /> 3D NFT (Beta)
        </Link>
        <div className="app-top-menu d-none d-lg-block ms-3">
          <Link to="/collection">
            Collection
          </Link>
          <a href={'https://goerli.etherscan.io/token/0x57FFe566DC9105cdAA3270951255820622110DfA'} target="_blank" rel="noreferrer" className="ms-3">
            Contract
          </a>
          <a href={'https://testnets.opensea.io/collection/3d-nft-beta-1'} target="_blank" rel="noreferrer" className="ms-3">
            Trade
          </a>
        </div>
        <Button type="button" className="navbar-toggler collapsed" variant="toogle-button" onClick={handleShowMenu}>
          <FontAwesomeIcon icon={solid('bars')} inverse />
        </Button>
        <Nav className="d-none d-lg-block ms-auto">
          {currentAccount
            ? <Link to="/account">
                <FontAwesomeIcon icon={solid('circle-user')}  className="fa-2x" color="#4542B9" />
              </Link>
            : <button className="btn-app" onClick={() => setConnectWalletModalShow(true)}>Connect Wallet</button>
          }
        </Nav>
      </Navbar>
      
      <ScrollToTop>
        <Routes>
          <Route path={"/"} element={<Home/>} />
          <Route path={"/collection"} element={<Collection/>} />
          <Route path={"/collection/:id"} element={<Item currentAccount={currentAccount} isOwner={isOwner} chainId={chainId} />} />
          <Route path={"/account"} element={<Account currentAccount={currentAccount} isOwner={isOwner} disconnectMetaMask={disconnectMetaMask} />} />
        </Routes>
      </ScrollToTop>

      <Offcanvas show={menuShow} onHide={handleCloseMenu} placement="top">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Link to="/" className="navbar-brand" onClick={handleCloseMenu}>
              <FontAwesomeIcon icon={solid('cube')} /> 3D NFT (Beta)
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul>
            <li>
              <Link to="/collection" onClick={handleCloseMenu}>
                Collection
              </Link>
            </li>
            <li>
              <a href={'https://goerli.etherscan.io/token/0x57FFe566DC9105cdAA3270951255820622110DfA'} target="_blank" rel="noreferrer" onClick={handleCloseMenu}>
                Contract
              </a>
            </li>
            <li>
              <a href={'https://testnets.opensea.io/collection/3d-nft-beta-1'} target="_blank" rel="noreferrer" onClick={handleCloseMenu}>
                Trade
              </a>
            </li>
            <li>
              {currentAccount
                ? <Link to="/account" onClick={handleCloseMenu}>
                    Account
                  </Link>
                : <button className="btn-app mt-3" onClick={() => setConnectWalletModalShow(true)}>Connect Wallet</button>
              }
            </li>
            
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={connectWalletModalShow} onHide={() => setConnectWalletModalShow(false)} size="lg" aria-labelledby="connect-wallet-modal" centered>
        <Modal.Header className="text-center" closeButton>
          <Modal.Title className="w-100" id="connect-wallet-modal">
            Connect Wallet
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="connect-wallet-modal-body">
          <button className="btn-connect-wallet w-30" onClick={connectMetaMask}>
            <img src={process.env.PUBLIC_URL + "/assets/icon-metamask.webp"} alt="" width="60px" height="60px" /><br/>MetaMask
          </button>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-app" onClick={() => setConnectWalletModalShow(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      
      <footer className="text-center text-lg-start bg-dark text-muted">
        <div className="text-center p-4">
          © 2022 Copyright Heng Shi Technology & Engineering Company Limited
        </div>
      </footer>

      <ToastContainer
        position="bottom-right"
        theme="light"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
    </div>
  );
}

export default App;
