import React, { Suspense, useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { ethers } from "ethers";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContractMetaMaskService from "../services/Contract-Metamask";
import loadData from "../data/data";
import Model from './Model';
import contract from "../contracts/Project_3D_NFT_Beta.json";

const Item = (props) => {
    const { id } = useParams();
    const [data, setData] = useState();

    const [ownerAddress, setOwnerAddress] = useState();
    const [events, setEvents] = useState([]);

    const [isCompleted, setCompleted] = useState(false);

    const getAddress = (address) => {
        let add = ethers.utils.defaultAbiCoder.decode(
            [ 'address' ],
            address
         );
        return add;
    };

    const getEvent = async () => {
        let provider = ethers.getDefaultProvider('goerli');
        const contractinfo = new ethers.Contract(contract.address, contract.abi, provider);

        let eventFilter = contractinfo.filters.Transfer();
        let events = await contractinfo.queryFilter(eventFilter);

        let encode_id = ethers.utils.defaultAbiCoder.encode(
            [ 'uint' ],
            [ id ]
        );
        let result = events.filter(e => e.topics[3] === encode_id);
        setEvents(result);

        setCompleted(true);
        
    };

    const ownerOf = async () => {
        let response = await ContractMetaMaskService.getOwnerOf(id);
        let data = JSON.parse(response);
        if(data.ownerOf !== undefined) {
            setOwnerAddress(data.ownerOf);
        }
    };

    const mint = async () => {
        if(!props.currentAccount) {
            showToast('Please connect account!');
        }
        else if (props.chainId !== 5) {
           showToast('You connected a wrong network!');
        }
        else {
            let response = await ContractMetaMaskService.getSalesStatus();
            let data = JSON.parse(response);
            if(data.saleActive === false) {
               showToast('Sales is not active yet!');
            }
            else {
                let response = await ContractMetaMaskService.mint(id);
                showToast('Request Sent!');
                let data = JSON.parse(response);
                if(data.hasOwnProperty("error")){
                    showToast('Error!');
                    if(data.error.message) {
                        //setAlertMessage(data.error.message); // User denied transaction signature
                        showToast(data.error.message);
                    }
                    else if(data.error.error.message) {
                        //setAlertMessage(data.error.error.message); // Contract require limitation
                        showToast(data.error.error.message);
                    }
                }
                else if(data.installmetamask === false) {
                    showToast('Please install MetaMask!');
                }
                else if(data.tx.hash){
                    showToast(data.tx.hash);
                }
                else {
                //alert(data.message);
                }
            }
        }
    };

    const showToast = (text) => {
        toast(text);
    }

    const loading = async () => {
        const loaddata = await loadData();
        loaddata.filter(({Id}) => Id === id).map((item,index) => (
            setData(item),
            document.title = item.Name + " | 3D NFT (Beta)"
        ));
        ownerOf();
        getEvent();

    };
    
    useEffect(() => {
        loading();
    }, [ownerAddress, events]);

    return (
    <>
        <div className="page-banner w-100">
            <div className="container col-xxl-8">
                 <div className="row">
                    <div className="col-12 text-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/"}>Home</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/collection"}>Collection</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">{data?.Name}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="page-banner-title text-center">
                <h2>{data?.Name}</h2>
            </div>
        </div>
        <div className="item-section mt-3">
            <div className="container col-xxl-8 px-4">
                <div className="row">
                    <div className="col-sm-12 col-lg-5 text-center">
                        <Model id={id} backgroundColor={data?.BackgroundColor} position={data?.Position} camera={data?.Camera} fov={data?.FOV} />
                    </div>
                    <div className="col-sm-12 col-lg-7 p-3">
                        {isCompleted
                            ? 
                                <>
                                    <p className="item-des"><label className="item-des-label">Id:</label> #{data?.Id}</p>
                                    <br />
                                    <p className="item-des"><label className="item-des-label">Name:</label> {data?.Name}</p>
                                    <br />    
                                    <p className="item-des"><label className="item-des-label">Type:</label> {data?.Type.substring(4)}</p>
                                    <br />
                                    <p className="item-des"><label className="item-des-label">Category:</label> {data?.Category.substring(4)}</p>
                                    <br />
                                    {ownerAddress
                                        ? 
                                            <>
                                                <p className="item-des"><label className="item-des-label">Owner:</label> {ownerAddress}</p>
                                                <br />
                                                <a href={'https://testnets.opensea.io/assets/goerli/0x57FFe566DC9105cdAA3270951255820622110DfA/' + id} className="btn-app" target="_blank" rel="noreferrer">
                                                    Trade
                                                </a>
                                            </>
                                        : <button className="btn-app" onClick={mint}>Mint</button>
                                    }
                                </>
                            :   (
                                <Skeleton count={5} />
                            )
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="card my-3">
                            <div className="card-body">
                                <p className="collection-name">Events</p>
                                {isCompleted
                                    ? (
                                        <div className="table-responsive table-events">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="col-3">Txn Hash</th>
                                                        <th scope="col" className="col-3">Action</th>
                                                        <th scope="col" className="col-3">Form</th>
                                                        <th scope="col" className="col-3">To</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {events.length > 0
                                                    ? (
                                                        events.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="col-3">
                                                                    <p><a href={'https://goerli.etherscan.io/tx/' + item['transactionHash']} target="_blank" rel="noreferrer">{item['transactionHash'].substring(0,10)}...</a></p></td>
                                                                <td className="col-3"><p>{item['event']}</p></td>
                                                                <td className="col-3"><p>{getAddress(item['topics'][1])}</p></td>
                                                                <td className="col-3"><p>{getAddress(item['topics'][2])}</p></td>
                                                            </tr>
                                                        ))
                                                    )
                                                    : (
                                                        <tr><td className="col-12" colSpan={4}><p className="events-no">No event yet</p></td></tr>
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                    : (
                                        <Skeleton count={3} />
                                    )

                                }
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="bottom-right"
                    theme="light"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    />
            </div>
        </div>

    </>
    );
};

export default Item;
