import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import HomeModel from './Home-Model';

function Home() {
    useEffect(() => {
        document.title = "3D NFT (Beta)";
    }, []);

    return (
    <>
        <div className="home-banner">
            <div className="home-banner-bg">
                <div className="home-banner-container py-5">
                    <div className="container col-xxl-8 px-4 pt-5">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-lg-6 home-banner-title order-lg-first order-last">
                                <div className="text-center text-md-start">
                                    <h2>3D NFT (Beta)</h2>
                                    <p className="pb-3">An ERC-721 NFT project with 3D models format(.glb) on Goerli Testnet.</p>
                                    <Link to={"/collection"} className="btn-app">Browse Collection</Link>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-6 text-center">
                                <div className="home-banner-img">
                                    <img src={process.env.PUBLIC_URL + '/assets/home-01.webp'} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

        <div className="home-section">
            <div className="py-5">
                <div className="container col-xxl-8 px-4 py-5">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2>Total <span className="highlight">50</span> items,<br />including 3D objects and 3D animations</h2>
                        </div>
                    </div>
                    <div className="row gap-3 gap-lg-0">
                        <div className="col-sm-12 col-lg-4 text-center">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h4 className="home-section-box-value">50</h4>
                                    <p className="home-section-box-title">Total</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4 text-center">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h4 className="home-section-box-value">41</h4>
                                    <p className="home-section-box-title">3D Object</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4 text-center">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h4 className="home-section-box-value">9</h4>
                                    <p className="home-section-box-title">3D Animation</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center pt-5">
                            <Link to={"/collection"} className="btn-app">Explore</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="home-section">
            <div className="home-section-content-animation">
                <div className="home-section-container py-5">
                    <div className="container col-xxl-8 px-4 py-3">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-lg-6 text-center">
                                <HomeModel />
                            </div>
                            <div className="col-12 col-sm-12 col-lg-6 home-section-des">
                                <h2>Attractive, interesting<br /><span className="highlight">3D motion</span> effect</h2>
                                <Link to={"/collection"} className="btn-app mt-3">Discover</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="home-section">
            <div className="home-section-content-goerli">
                <div className="home-section-container py-5">
                    <div className="container col-xxl-8 px-4 py-5">
                        <div className="row">
                            <div className="col-12 home-section-des">
                                <h2>Deployed on<br />Ethereum's <span className="highlight">Goerli</span> testnet</h2>
                            </div>
                            <div className="col-12 home-section-des">
                                <div className="home-section-eth-circle">
                                    <div className="home-section-eth-icon icon-1">
                                        <img src={process.env.PUBLIC_URL + '/assets/preview-image/14.webp'} alt="" className="w-100 h-100" />
                                    </div>
                                    <div className="home-section-eth-icon icon-2">
                                        <img src={process.env.PUBLIC_URL + '/assets/preview-image/39.webp'} alt="" className="w-100 h-100" />
                                    </div>
                                    <div className="home-section-eth-icon icon-3">
                                        <img src={process.env.PUBLIC_URL + '/assets/preview-image/9.webp'} alt="" className="w-100 h-100" />
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-12 home-section-des d-block pt-5">
                                <p className="mb-4">Contract address: 0x57FFe566DC9105cdAA3270951255820622110DfA</p>
                                <a href={'https://goerli.etherscan.io/token/0x57FFe566DC9105cdAA3270951255820622110DfA'} target="_blank" rel="noreferrer" className="btn-app">Smart Contract</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default Home;
