/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('assets/glb-file/42.glb')
  const { actions } = useAnimations(animations, group)

  useEffect(() => {
    //actions['wave'].play();
    //actions['suggest'].play();
    //actions['Idle3_1'].play();
    //actions['GetAttention'].play();
    //actions['GestureRight'].play();
    //actions['GestureLeft'].play();
    //actions['DoMagic2'].play();
    //actions['Congratulate2'].play();
    //actions['Congratulate'].play();
    //actions['Confused'].play();
    actions['Annouce'].play();
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group name="Geometry">
          <group name="Merlin_Props">
            <skinnedMesh name="Merlin_magic_stick" geometry={nodes.Merlin_magic_stick.geometry} material={nodes.Merlin_magic_stick.material} skeleton={nodes.Merlin_magic_stick.skeleton} />
            <skinnedMesh name="Merlin_trumpet" geometry={nodes.Merlin_trumpet.geometry} material={nodes.Merlin_trumpet.material} skeleton={nodes.Merlin_trumpet.skeleton} />
            <skinnedMesh name="Merlin_bulb" geometry={nodes.Merlin_bulb.geometry} material={nodes.Merlin_bulb.material} skeleton={nodes.Merlin_bulb.skeleton} />
            <skinnedMesh name="Merlin_trophy" geometry={nodes.Merlin_trophy.geometry} material={nodes.Merlin_trophy.material} skeleton={nodes.Merlin_trophy.skeleton} />
            <skinnedMesh name="Merlin_clouds" geometry={nodes.Merlin_clouds.geometry} material={nodes.Merlin_clouds.material} skeleton={nodes.Merlin_clouds.skeleton} />
            <skinnedMesh name="Merlin_sparkles" geometry={nodes.Merlin_sparkles.geometry} material={nodes.Merlin_sparkles.material} skeleton={nodes.Merlin_sparkles.skeleton} />
          </group>
          <skinnedMesh name="Merlin" geometry={nodes.Merlin.geometry} material={nodes.Merlin.material} skeleton={nodes.Merlin.skeleton} />
        </group>
        <primitive object={nodes.Root} />
      </group>
    </group>
  )
}

//useGLTF.preload('assets/glb-file/42.glb')
