import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContractMetaMaskService from "../services/Contract-Metamask";

const Account = (props) => {

    const [accountCollection, setAccountCollection] = useState([]);
    const [isCompleted, setCompleted] = useState(false);

    const disconnectMetaMask = async() => {
        props.disconnectMetaMask();
        setAccountCollection([]);
    };

    const getAccountCollection = async() => {
        let response = await ContractMetaMaskService.getAccountCollection();
        let data = JSON.parse(response);
        if(data.installmetamask === false) {
            showToast("Please install MetaMask!");
        }
        else {
            setAccountCollection(data.collection);
        }
        setCompleted(true);
    };

    const withdrawAll = async() => {
        if(!props.currentAccount) {
          alert("Please connect account!");
        }
        else {
          let response = await ContractMetaMaskService.withdrawAll();
          showToast("Request Sent!");
          let data = JSON.parse(response);
          if(data.installmetamask === false) {
            showToast("Please install MetaMask!");
          }
          else if(data.tx.hash){
            showToast(data.tx.hash);
          }
        }
    };

    const showToast = (text) => {
        toast(text);
    }

    useEffect(() => {
        document.title = "Account | 3D NFT (Beta)";
        getAccountCollection();
    }, []);

    return (
    <>
        <div className="page-banner w-100">
            <div className="container col-xxl-8">
                 <div className="row">
                    <div className="col-12 text-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/"}>Home</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Account</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="page-banner-title text-center">
                <h2>Account</h2>
            </div>
        </div>
        <div className="collection-section mt-3">
            <div className="container col-xxl-8 px-4">
                <div className="row">
                    <div className="col-12 text-center">
                        <p className="item-des"><label className="item-des-label">Account:</label><br/>{props.currentAccount}</p>
                        {props.currentAccount
                            ? <button className="btn-app" onClick={disconnectMetaMask}>Disconnect</button>
                            : ""
                        }
                        
                    </div>
                </div>
                {props.isOwner
                    ? (
                        <div className="row mt-3">
                            <div className="col-12 text-center">
                                <p className="item-des"><label className="item-des-label">Admin Function:</label></p>
                                <button className="btn-app" onClick={withdrawAll}>Withdraw All ETH</button>
                            </div>
                        </div>
                    )
                    : ""
                }
                <div className="row gap-3 gap-lg-0 text-center my-3">
                        <p className="item-des"><label className="item-des-label">My Items:</label></p>
                        {isCompleted
                            ? (
                                props.currentAccount && accountCollection && accountCollection.length > 0
                                    ? (accountCollection).map((item, index) => (
                                        <div className="col-sm-12 col-lg-3 mb-lg-3 text-center" key={index}>
                                            <Link to={"/collection/" + parseInt(item['hex'])}>
                                                <div className="card h-100">
                                                    <LazyLoadImage
                                                        alt=""
                                                        effect="blur"
                                                        src={process.env.PUBLIC_URL + '/assets/preview-image/' + parseInt(item['hex']).toString() + '.webp'}
                                                        className="collection-image w-100 h-100" />
                                                    <div className="card-body pt-1 pb-1">
                                                        <p className="collection-id">#{parseInt(item['hex'])}</p>
                                                        <p className="collection-name">{item['Name']}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                    : <p className="mb-0 p-des">No item yet</p>
                                
                            )
                            : (
                                <Skeleton count={3} />
                            )
                        }
                        
      
                </div>
            </div>
        </div>
    </>
    );
}

export default Account;
