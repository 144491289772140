/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('assets/glb-file/15.glb')
  return (
    <group {...props} dispose={null}>
      <group scale={0.1}>
        <mesh geometry={nodes.mesh_6.geometry} material={nodes.mesh_6.material} />
        <mesh geometry={nodes.mesh_7.geometry} material={nodes.mesh_7.material} />
        <mesh geometry={nodes.polySurface5911.geometry} material={nodes.polySurface5911.material} />
        <mesh geometry={nodes.polySurface546.geometry} material={nodes.polySurface546.material} />
        <mesh geometry={nodes.polySurface557.geometry} material={nodes.polySurface557.material} />
        <mesh geometry={nodes.polySurface568.geometry} material={nodes.polySurface568.material} />
        <mesh geometry={nodes.mesh_12.geometry} material={nodes.mesh_12.material} />
        <mesh geometry={nodes.mesh_13.geometry} material={nodes.mesh_13.material} />
        <mesh geometry={nodes.mesh_15.geometry} material={nodes.mesh_15.material} />
        <mesh geometry={nodes.mesh_16.geometry} material={nodes.mesh_16.material} />
        <mesh geometry={nodes.polySurface5126.geometry} material={nodes.polySurface5126.material} />
        <mesh geometry={nodes.polySurface1717.geometry} material={nodes.polySurface1717.material} />
        <mesh geometry={nodes.License_plate2.geometry} material={nodes.License_plate2.material} />
        <mesh geometry={nodes.exhaust3.geometry} material={nodes.exhaust3.material} />
        <mesh geometry={nodes.emblem14.geometry} material={nodes.emblem14.material} />
        <mesh geometry={nodes.Seats12.geometry} material={nodes.Seats12.material} />
        <mesh geometry={nodes.pCube313.geometry} material={nodes.pCube313.material} />
        <mesh geometry={nodes.body_misc14.geometry} material={nodes.body_misc14.material} />
        <group position={[0.28, 0.02, -0.14]} rotation={[-0.01, -0.24, -0.05]}>
          <mesh geometry={nodes.mesh_18.geometry} material={nodes.mesh_18.material} />
          <mesh geometry={nodes.mesh_19.geometry} material={nodes.mesh_19.material} />
          <mesh geometry={nodes.mesh_20.geometry} material={nodes.mesh_20.material} />
        </group>
        <mesh geometry={nodes.mesh_21.geometry} material={nodes.mesh_21.material} />
        <mesh geometry={nodes.mesh_22.geometry} material={nodes.mesh_22.material} />
        <mesh geometry={nodes.mesh_23.geometry} material={nodes.mesh_23.material} />
        <group position={[0.23, -0.05, 0.2]} rotation={[-0.01, -0.24, -0.05]}>
          <mesh geometry={nodes.mesh_24.geometry} material={nodes.mesh_24.material} />
          <mesh geometry={nodes.mesh_25.geometry} material={nodes.mesh_25.material} />
          <mesh geometry={nodes.mesh_26.geometry} material={nodes.mesh_26.material} />
        </group>
        <mesh geometry={nodes.mesh_27.geometry} material={nodes.mesh_27.material} />
        <mesh geometry={nodes.mesh_28.geometry} material={nodes.mesh_28.material} />
        <mesh geometry={nodes.mesh_29.geometry} material={nodes.mesh_29.material} />
        <mesh geometry={nodes.mesh_30.geometry} material={nodes.mesh_30.material} />
        <mesh geometry={nodes.mesh_31.geometry} material={nodes.mesh_31.material} />
        <mesh geometry={nodes.mesh_32.geometry} material={nodes.mesh_32.material} />
        <mesh geometry={nodes.mesh_33.geometry} material={nodes.mesh_33.material} />
        <mesh geometry={nodes.mesh_34.geometry} material={nodes.mesh_34.material} />
        <mesh geometry={nodes.mesh_35.geometry} material={nodes.mesh_35.material} />
        <mesh geometry={nodes.mesh_36.geometry} material={nodes.mesh_36.material} />
        <mesh geometry={nodes.mesh_39.geometry} material={nodes.mesh_39.material} />
        <mesh geometry={nodes.mesh_40.geometry} material={nodes.mesh_40.material} />
        <mesh geometry={nodes.mesh_41.geometry} material={nodes.mesh_41.material} />
        <mesh geometry={nodes.mesh_42.geometry} material={nodes.mesh_42.material} />
        <mesh geometry={nodes.mesh_44.geometry} material={nodes.mesh_44.material} />
        <mesh geometry={nodes.mesh_45.geometry} material={nodes.mesh_45.material} />
        <mesh geometry={nodes.mesh_46.geometry} material={nodes.mesh_46.material} />
        <mesh geometry={nodes.mesh_47.geometry} material={nodes.mesh_47.material} />
        <mesh geometry={nodes.mesh_48.geometry} material={nodes.mesh_48.material} />
        <mesh geometry={nodes.mesh_49.geometry} material={nodes.mesh_49.material} />
        <mesh geometry={nodes.mesh_50.geometry} material={nodes.mesh_50.material} />
        <mesh geometry={nodes.mesh_51.geometry} material={nodes.mesh_51.material} />
        <mesh geometry={nodes.mesh_52.geometry} material={nodes.mesh_52.material} />
        <mesh geometry={nodes.mesh_53.geometry} material={nodes.mesh_53.material} />
        <mesh geometry={nodes.mesh_54.geometry} material={nodes.mesh_54.material} />
        <mesh geometry={nodes.mesh_55.geometry} material={nodes.mesh_55.material} />
        <mesh geometry={nodes.polySurface2635.geometry} material={nodes.polySurface2635.material} />
        <mesh geometry={nodes.polySurface3236.geometry} material={nodes.polySurface3236.material} />
        <mesh geometry={nodes.polySurface6139.geometry} material={nodes.polySurface6139.material} />
      </group>
    </group>
  )
}

//useGLTF.preload('assets/glb-file/15.glb')
