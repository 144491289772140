/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('assets/glb-file/16.glb')
  return (
    <group {...props} dispose={null}>
      <group scale={0.1}>
        <mesh geometry={nodes.mesh_1.geometry} material={nodes.mesh_1.material} />
        <mesh geometry={nodes.mesh_2.geometry} material={nodes.mesh_2.material} />
        <mesh geometry={nodes.mesh_3.geometry} material={nodes.mesh_3.material} />
        <mesh geometry={nodes.mesh_4.geometry} material={nodes.mesh_4.material} />
        <mesh geometry={nodes.mesh_5.geometry} material={nodes.mesh_5.material} />
        <mesh geometry={nodes.mesh_6.geometry} material={nodes.mesh_6.material} />
        <mesh geometry={nodes.polySurface2779.geometry} material={nodes.polySurface2779.material} />
        <mesh geometry={nodes.polySurface27810.geometry} material={nodes.polySurface27810.material} />
        <mesh geometry={nodes.polySurface27312.geometry} material={nodes.polySurface27312.material} />
        <mesh geometry={nodes.polySurface23213.geometry} material={nodes.polySurface23213.material} />
        <mesh geometry={nodes.mesh_12.geometry} material={nodes.mesh_12.material} />
        <mesh geometry={nodes.mesh_13.geometry} material={nodes.mesh_13.material} />
        <mesh geometry={nodes.mesh_14.geometry} material={nodes.mesh_14.material} />
        <mesh geometry={nodes.Door_handle2.geometry} material={nodes.Door_handle2.material} />
        <mesh geometry={nodes.License_plate14.geometry} material={nodes.License_plate14.material} />
        <mesh geometry={nodes.mesh_15.geometry} material={nodes.mesh_15.material} />
        <mesh geometry={nodes.mesh_16.geometry} material={nodes.mesh_16.material} />
        <mesh geometry={nodes.mesh_17.geometry} material={nodes.mesh_17.material} />
        <mesh geometry={nodes.mesh_18.geometry} material={nodes.mesh_18.material} />
        <mesh geometry={nodes.mesh_19.geometry} material={nodes.mesh_19.material} />
        <mesh geometry={nodes.mesh_20.geometry} material={nodes.mesh_20.material} />
        <mesh geometry={nodes.mesh_21.geometry} material={nodes.mesh_21.material} />
        <mesh geometry={nodes.mesh_22.geometry} material={nodes.mesh_22.material} />
        <mesh geometry={nodes.mesh_23.geometry} material={nodes.mesh_23.material} />
        <mesh geometry={nodes.mesh_24.geometry} material={nodes.mesh_24.material} />
        <mesh geometry={nodes.mesh_25.geometry} material={nodes.mesh_25.material} />
        <mesh geometry={nodes.mesh_26.geometry} material={nodes.mesh_26.material} />
        <mesh geometry={nodes.mesh_27.geometry} material={nodes.mesh_27.material} />
        <mesh geometry={nodes.mesh_28.geometry} material={nodes.mesh_28.material} />
        <mesh geometry={nodes.mesh_29.geometry} material={nodes.mesh_29.material} />
        <mesh geometry={nodes.mesh_30.geometry} material={nodes.mesh_30.material} />
        <mesh geometry={nodes.mesh_31.geometry} material={nodes.mesh_31.material} />
        <mesh geometry={nodes.mesh_32.geometry} material={nodes.mesh_32.material} />
        <mesh geometry={nodes.mesh_33.geometry} material={nodes.mesh_33.material} />
        <mesh geometry={nodes.mesh_34.geometry} material={nodes.mesh_34.material} />
        <mesh geometry={nodes.mesh_35.geometry} material={nodes.mesh_35.material} />
        <mesh geometry={nodes.mesh_36.geometry} material={nodes.mesh_36.material} />
        <mesh geometry={nodes.mesh_37.geometry} material={nodes.mesh_37.material} />
        <mesh geometry={nodes.mesh_38.geometry} material={nodes.mesh_38.material} />
        <mesh geometry={nodes.mesh_39.geometry} material={nodes.mesh_39.material} />
        <mesh geometry={nodes.mesh_40.geometry} material={nodes.mesh_40.material} />
        <mesh geometry={nodes.mesh_41.geometry} material={nodes.mesh_41.material} />
        <mesh geometry={nodes.mesh_42.geometry} material={nodes.mesh_42.material} />
        <mesh geometry={nodes.mesh_43.geometry} material={nodes.mesh_43.material} />
        <mesh geometry={nodes.mesh_44.geometry} material={nodes.mesh_44.material} />
        <mesh geometry={nodes.mesh_45.geometry} material={nodes.mesh_45.material} />
        <mesh geometry={nodes.mesh_46.geometry} material={nodes.mesh_46.material} />
        <mesh geometry={nodes.mesh_47.geometry} material={nodes.mesh_47.material} />
        <mesh geometry={nodes.mesh_49.geometry} material={nodes.mesh_49.material} />
        <mesh geometry={nodes.mesh_50.geometry} material={nodes.mesh_50.material} />
        <mesh geometry={nodes.polySurface27422.geometry} material={nodes.polySurface27422.material} />
      </group>
    </group>
  )
}

//useGLTF.preload('assets/glb-file/16.glb')
