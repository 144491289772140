/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('assets/glb-file/37.glb')
  return (
    <group {...props} dispose={null}>
      <group scale={0.1}>
        <mesh geometry={nodes.polySurface1681.geometry} material={nodes.polySurface1681.material} />
      </group>
    </group>
  )
}

//useGLTF.preload('assets/glb-file/37.glb')
