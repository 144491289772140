/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('assets/glb-file/43.glb')
  const { actions } = useAnimations(animations, group)

  useEffect(() => {
    //actions['walk_proud'].play();
    //actions['dance'].play();
    actions['idle'].play();
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group name="RootNode0" scale={0.01}>
          <group name="skeletal1">
            <primitive object={nodes.Root_J2} />
          </group>
          <skinnedMesh name="cow1125" geometry={nodes.cow1125.geometry} material={nodes.cow1125.material} skeleton={nodes.cow1125.skeleton} />
        </group>
      </group>
    </group>
  )
}

//useGLTF.preload('assets/glb-file/43.glb')
