/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('assets/glb-file/48.glb')
  const { actions } = useAnimations(animations, group)

  useEffect(() => {
    //actions['Animation7'].play();
    //actions['Animation6'].play();
    //actions['Animation5'].play();
    //actions['Animation4'].play();
    //actions['Animation3'].play();
    //actions['Animation2'].play();
    actions['Animation1'].play();
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group name="Geometry">
          <group name="Links_Props">
            <skinnedMesh name="Links_glasses" geometry={nodes.Links_glasses.geometry} material={nodes.Links_glasses.material} skeleton={nodes.Links_glasses.skeleton} />
            <skinnedMesh name="Links_laptop" geometry={nodes.Links_laptop.geometry} material={nodes.Links_laptop.material} skeleton={nodes.Links_laptop.skeleton} />
            <skinnedMesh name="Links_notebook" geometry={nodes.Links_notebook.geometry} material={nodes.Links_notebook.material} skeleton={nodes.Links_notebook.skeleton} />
            <skinnedMesh name="Links_pencil" geometry={nodes.Links_pencil.geometry} material={nodes.Links_pencil.material} skeleton={nodes.Links_pencil.skeleton} />
            <skinnedMesh name="Links_carpet" geometry={nodes.Links_carpet.geometry} material={nodes.Links_carpet.material} skeleton={nodes.Links_carpet.skeleton} />
          </group>
          <skinnedMesh name="Links" geometry={nodes.Links.geometry} material={nodes.Links.material} skeleton={nodes.Links.skeleton} />
        </group>
        <primitive object={nodes.Root} />
      </group>
    </group>
  )
}

//useGLTF.preload('assets/glb-file/48.glb')
