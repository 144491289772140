/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('assets/glb-file/44.glb')
  const { actions } = useAnimations(animations, group)

  useEffect(() => {
    actions['bubble_blow'].play();
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group name="RootNode0" scale={0.1}>
          <group name="skeletal1">
            <primitive object={nodes.root2} />
          </group>
          <group name="exportMarker_11128_bubble_character137" />
          <group name="pathMarker138" />
          <skinnedMesh name="bubble_character_new2139" geometry={nodes.bubble_character_new2139.geometry} material={nodes.bubble_character_new2139.material} skeleton={nodes.bubble_character_new2139.skeleton} />
        </group>
      </group>
    </group>
  )
}

//useGLTF.preload('assets/glb-file/44.glb')
