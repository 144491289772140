import React, { Suspense, useEffect, useState, useContext } from "react";
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Html, useProgress } from '@react-three/drei';
import loadData from "../data/data";
import Model45 from '../assets/45';

const Model = () => {
    return (
    <>
        <Canvas
            camera={{ position: [0, 0, 12], fov: 12 }}
            className="home-model-area"
        >
            <ambientLight intensity={1.25} />
            <ambientLight intensity={0.1} />
            <directionalLight intensity={0.4} />
            <Suspense>
                <Model45 position={[0, -0.8, 0]} />
            </Suspense>
        </Canvas>
    </>
    );
};

export default Model;
