import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import CollectionPagination from './Collection-Pagination';

function Collection() {
    const pageinationRef = useRef(null);

    const [dataType, setDataType] = useState("All");
    const [dataCategory, setDataCategory] = useState("All");
    const [dataSort, setDataSort] = useState("2");

    const onChangeType = (e) => {
        const value = e.target.value;
        setDataType(value);
        pageinationRef.current.resetItemOffset();
    };

    const onChangeCategory = (e) => {
        const value = e.target.value;
        setDataCategory(value);
        pageinationRef.current.resetItemOffset();
    };

    const onChangeSort = (e) => {
        const value = e.target.value;
        setDataSort(value);
      };
    

    useEffect(() => {
        document.title = "Collection | 3D NFT (Beta)";
    }, []);

    return (
    <>
        <div className="page-banner w-100">
            <div className="container col-xxl-8">
                 <div className="row">
                    <div className="col-12 text-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/"}>Home</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Collection</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="page-banner-title text-center">
                <h2>Collection</h2>
            </div>
        </div>
        <div className="collection-filter-section mt-3">
            <div className="container col-xxl-8 px-4">
                <div className="row">
                    <div className="form-group form-label col-12 col-lg-1">
                        <label htmlFor="type" className="form-label-title">Type:</label>
                    </div>
                    <div className="form-group col-12 col-lg-11" onChange={onChangeType}>

                        <input type="radio" className="btn-check" name="options-type" id="type-all" value="All" autoComplete="off" checked={dataType === 'All'} onChange={onChangeType} />
                        <label className="btn btn-filter rounded-pill me-1" htmlFor="type-all">All</label>

                        <input type="radio" className="btn-check" name="options-type" id="type-3d-object" value="3D Object" autoComplete="off" checked={dataType === '3D Object'} onChange={onChangeType} />
                        <label className="btn btn-filter rounded-pill me-1" htmlFor="type-3d-object">3D Object</label>

                        <input type="radio" className="btn-check" name="options-type" id="type-3d-animation" value="3D Animation" autoComplete="off" checked={dataType === '3D Animation'} onChange={onChangeType} />
                        <label className="btn btn-filter rounded-pill" htmlFor="type-3d-animation">3D Animation</label>

                    </div>
                    <div className="form-group form-label col-12 col-lg-1 mt-2">
                        <label htmlFor="gender" className="form-label-title">Category:</label>
                    </div>
                    <div className="col-12 col-lg-11 mt-2" onChange={onChangeCategory}>

                        <input type="radio" className="btn-check" name="options-category" id="category-all" value="All" autoComplete="off" checked={dataCategory === 'All'} onChange={onChangeCategory} />
                        <label className="btn btn-filter rounded-pill me-1" htmlFor="category-all">All</label>

                        <input type="radio" className="btn-check" name="options-category" id="category-character" value="Character" autoComplete="off" checked={dataCategory === 'Character'} onChange={onChangeCategory} />
                        <label className="btn btn-filter rounded-pill me-1" htmlFor="category-character">Character</label>

                        <input type="radio" className="btn-check" name="options-category" id="category-food" value="Food" autoComplete="off" checked={dataCategory === 'Food'} onChange={onChangeCategory} />
                        <label className="btn btn-filter rounded-pill me-1" htmlFor="category-food">Food</label>

                        <input type="radio" className="btn-check" name="category-category" id="category-transportation" value="Transportation" autoComplete="off" checked={dataCategory === 'Transportation'} onChange={onChangeCategory} />
                        <label className="btn btn-filter rounded-pill me-1" htmlFor="category-transportation">Transportation</label>

                        <input type="radio" className="btn-check" name="options-category" id="category-building" value="Building" autoComplete="off" checked={dataCategory === 'Building'} onChange={onChangeCategory} />
                        <label className="btn btn-filter rounded-pill me-1" htmlFor="category-building">Building</label>

                        <input type="radio" className="btn-check" name="options-category" id="category-nature" value="Nature" autoComplete="off" checked={dataCategory === 'Nature'} onChange={onChangeCategory} />
                        <label className="btn btn-filter rounded-pill me-1" htmlFor="category-nature">Nature</label>

                        <input type="radio" className="btn-check" name="options-category" id="category-emoji" value="Emoji" autoComplete="off" checked={dataCategory === 'Emoji'} onChange={onChangeCategory} />
                        <label className="btn btn-filter rounded-pill me-1" htmlFor="category-emoji">Emoji</label>

                        <input type="radio" className="btn-check" name="options-category" id="category-animation" value="Animation" autoComplete="off" checked={dataCategory === 'Animation'} onChange={onChangeCategory} />
                        <label className="btn btn-filter rounded-pill" htmlFor="category-animation">Animation</label>

                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-12 mt-2 d-flex align-items-center justify-content-end">
                        <label htmlFor="sort" className="form-label-title form-label-sort">Sort By:</label>
                        <select
                            className="form-select form-select-sort ms-1"
                            value={dataSort}
                            onChange={onChangeSort}
                            aria-label="sort">
                            <option value="0">Id: Ascending</option>
                            <option value="1">Id: Descending</option>
                            <option value="2">Name: A to Z</option>
                            <option value="3">Name: Z to A</option>
                          </select>
                    </div>
                    
                </div>
            </div>
        </div>
        <div className="collection-section mt-3">
            <div className="container col-xxl-8 px-4">
                <div className="row gap-3 gap-lg-0">
                    <CollectionPagination dataType={dataType} dataCategory={dataCategory} dataSort={dataSort} ref={pageinationRef} itemsPerPage={12} />
                </div>
            </div>
        </div>
    </>
    );
}

export default Collection;
