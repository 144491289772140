const data = async () => {

    const data = [
        {
            "Id": "0",
            "Name": "Baby Skunk",
            "Category": "All,Character",
            "Type": "All,3D Object",
            "BackgroundColor": "#eda2a2",
            "Position": [0, -0.5, 0],
            "Camera": [0, 0, 12],
            "FOV": 8,
        },
        {
            "Id": "1",
            "Name": "Fawn",
            "Category": "All,Character",
            "Type": "All,3D Object",
            "BackgroundColor": "#bcaaf5",
            "Position": [0, -0.25, 0],
            "Camera": [0, 0, 12],
            "FOV": 3.5,
        },
        {
            "Id": "2",
            "Name": "Kitten",
            "Category": "All,Character",
            "Type": "All,3D Object",
            "BackgroundColor": "#63c5c7",
            "Position": [0, -0.75, 0],
            "Camera": [0, 0, 12],
            "FOV": 10.5,
        },
        {
            "Id": "3",
            "Name": "Squirrel",
            "Category": "All,Character",
            "Type": "All,3D Object",
            "BackgroundColor": "#fbf4ad",
            "Position": [0, -0.28, 0],
            "Camera": [0, 0, 12],
            "FOV": 3.8,
        },
        {
            "Id": "4",
            "Name": "Chick",
            "Category": "All,Character",
            "Type": "All,3D Object",
            "BackgroundColor": "#ccfccb",
            "Position": [0, -0.12, 0],
            "Camera": [0, 0, 12],
            "FOV": 1.65,
        },
        {
            "Id": "5",
            "Name": "Puppy",
            "Category": "All,Character",
            "Type": "All,3D Object",
            "BackgroundColor": "#1eb7d1",
            "Position": [0, -0.35, 0],
            "Camera": [0, 0, 12],
            "FOV": 4.6,
        },
        {
            "Id": "6",
            "Name": "Bunny",
            "Category": "All,Character",
            "Type": "All,3D Object",
            "BackgroundColor": "#ffb356",
            "Position": [0, -1.1, 0],
            "Camera": [0, 0, 12],
            "FOV": 13.8,
        },
        {
            "Id": "7",
            "Name": "Greeting Dog",
            "Category": "All,Character",
            "Type": "All,3D Object",
            "BackgroundColor": "#bcaaf5",
            "Position": [0, -0.22, 0],
            "Camera": [0, 0, 12],
            "FOV": 3.15,
        },
        {
            "Id": "8",
            "Name": "Baby Zebra",
            "Category": "All,Character",
            "Type": "All,3D Object",
            "BackgroundColor": "#ccfccb",
            "Position": [0, -0.42, 0],
            "Camera": [0, 0, 12],
            "FOV": 6,
        },
        {
            "Id": "9",
            "Name": "Orange Critter",
            "Category": "All,Character",
            "Type": "All,3D Object",
            "BackgroundColor": "#fbf4ad",
            "Position": [0, -0.42, 0],
            "Camera": [0, 0, 12],
            "FOV": 5.6,
        },
        {
            "Id": "10",
            "Name": "Smiling Critter",
            "Category": "All,Character",
            "Type": "All,3D Object",
            "BackgroundColor": "#eda2a2",
            "Position": [0, -0.45, 0],
            "Camera": [0, 0, 12],
            "FOV": 6.8,
        },
        {
            "Id": "11",
            "Name": "Cheeseburger",
            "Category": "All,Food",
            "Type": "All,3D Object",
            "BackgroundColor": "#bcaaf5",
            "Position": [0, -0.6, 0],
            "Camera": [0, 0, 12],
            "FOV": 9,
        },
        {
            "Id": "12",
            "Name": "Ice Cream Cone",
            "Category": "All,Food",
            "Type": "All,3D Object",
            "BackgroundColor": "#1eb7d1",
            "Position": [0, -0.35, 0],
            "Camera": [0, 0, 12],
            "FOV": 4.8,
        },
        {
            "Id": "13",
            "Name": "Birthday Cake",
            "Category": "All,Food",
            "Type": "All,3D Object",
            "BackgroundColor": "#63c5c7",
            "Position": [0, -0.15, 0],
            "Camera": [0, 0, 12],
            "FOV": 3.6,
        },
        {
            "Id": "14",
            "Name": "Mini Race Car",
            "Category": "All,Transportation",
            "Type": "All,3D Object",
            "BackgroundColor": "#ffb356",
            "Position": [0, -0.5, 0],
            "Camera": [0, 0, 12],
            "FOV": 10,
        },
        {
            "Id": "15",
            "Name": "Traveler",
            "Category": "All,Transportation",
            "Type": "All,3D Object",
            "BackgroundColor": "#fbf4ad",
            "Position": [0, -0.08, 0],
            "Camera": [0, 0, 12],
            "FOV": 1.8,
        },
        {
            "Id": "16",
            "Name": "Shift Tuner",
            "Category": "All,Transportation",
            "Type": "All,3D Object",
            "BackgroundColor": "#ccfccb",
            "Position": [0, -0.03, 0],
            "Camera": [0, 0, 12],
            "FOV": 1.46,
        },
        {
            "Id": "17",
            "Name": "Steam Train",
            "Category": "All,Transportation",
            "Type": "All,3D Object",
            "BackgroundColor": "#bcaaf5",
            "Position": [0, -1, 0],
        },
        {
            "Id": "18",
            "Name": "Plane",
            "Category": "All,Transportation",
            "Type": "All,3D Object",
            "BackgroundColor": "#ffb356",
            "Position": [0, -1, 0],
            "Camera": [0, 0, 12],
            "FOV": 24,
        },
        {
            "Id": "19",
            "Name": "Helicopter",
            "Category": "All,Transportation",
            "Type": "All,3D Object",
            "BackgroundColor": "#eda2a2",
            "Position": [0, -0.5, 0],
            "Camera": [0, 0, 12],
            "FOV": 16,
        },
        {
            "Id": "20",
            "Name": "Sailboat",
            "Category": "All,Transportation",
            "Type": "All,3D Object",
            "BackgroundColor": "#ccfccb",
            "Position": [0, -10, 0],
            "Camera": [0, 0, 100],
            "FOV": 15,
        },
        {
            "Id": "21",
            "Name": "Coast Guard",
            "Category": "All,Transportation",
            "Type": "All,3D Object",
            "BackgroundColor": "#63c5c7",
            "Position": [0, -0.5, 0],
            "Camera": [0, 0, 12],
            "FOV": 16,
        },
        {
            "Id": "22",
            "Name": "Candy House",
            "Category": "All,Building",
            "Type": "All,3D Object",
            "BackgroundColor": "#bcaaf5",
            "Position": [0, -0.35, 0],
            "Camera": [0, 0, 12],
            "FOV": 5,
        },
        {
            "Id": "23",
            "Name": "Residential House",
            "Category": "All,Building",
            "Type": "All,3D Object",
            "BackgroundColor": "#fbf4ad",
            "Position": [0, -0.35, 0],
            "Camera": [0, 0, 12],
            "FOV": 8,
        },
        {
            "Id": "24",
            "Name": "Gingerbread House",
            "Category": "All,Building",
            "Type": "All,3D Object",
            "BackgroundColor": "#1eb7d1",
            "Position": [0, -0.75, 0],
            "Camera": [0, 0, 12],
            "FOV": 18,
        },
        {
            "Id": "25",
            "Name": "Castle",
            "Category": "All,Building",
            "Type": "All,3D Object",
            "BackgroundColor": "#eda2a2",
            "Position": [0.015, -0.2, 0],
            "Camera": [0, 0, 12],
            "FOV": 3,
        },
        {
            "Id": "26",
            "Name": "Pyramid",
            "Category": "All,Building",
            "Type": "All,3D Object",
            "BackgroundColor": "#ccfccb",
            "Position": [0, -1.5, 0],
            "Camera": [0, 0, 12],
            "FOV": 26,
        },
        {
            "Id": "27",
            "Name": "Winter Cabin",
            "Category": "All,Building",
            "Type": "All,3D Object",
            "BackgroundColor": "#1eb7d1",
            "Position": [0, -2.25, 0],
            "Camera": [0, 0, 12],
            "FOV": 38,
        },
        {
            "Id": "28",
            "Name": "Igloo",
            "Category": "All,Building",
            "Type": "All,3D Object",
            "BackgroundColor": "#eda2a2",
            "Position": [0, -0.5, 0],
            "Camera": [0, 0, 12],
            "FOV": 15,
        },
        {
            "Id": "29",
            "Name": "Skyscraper",
            "Category": "All,Building",
            "Type": "All,3D Object",
            "BackgroundColor": "#ffb356",
            "Position": [0, -2.5, 0],
            "Camera": [0, 0, 12],
            "FOV": 38,
        },
        {
            "Id": "30",
            "Name": "Rose",
            "Category": "All,Nature",
            "Type": "All,3D Object",
            "BackgroundColor": "#fbf4ad",
            "Position": [0, -0.18, 0],
            "Camera": [0, 0, 12],
            "FOV": 2.5,
        },
        {
            "Id": "31",
            "Name": "Daisy",
            "Category": "All,Nature",
            "Type": "All,3D Object",
            "BackgroundColor": "#63c5c7",
            "Position": [0, -0.26, 0],
            "Camera": [0, 0, 12],
            "FOV": 3.8,
        },
        {
            "Id": "32",
            "Name": "Tulip",
            "Category": "All,Nature",
            "Type": "All,3D Object",
            "BackgroundColor": "#bcaaf5",
            "Position": [0.03, -0.2, 0],
            "Camera": [0, 0, 12],
            "FOV": 2.8,
        },
        {
            "Id": "33",
            "Name": "Cactus",
            "Category": "All,Nature",
            "Type": "All,3D Object",
            "BackgroundColor": "#eda2a2",
            "Position": [0, -0.58, 0],
            "Camera": [0, 0, 12],
            "FOV": 8,
        },
        {
            "Id": "34",
            "Name": "Stylized Fir Tree",
            "Category": "All,Nature",
            "Type": "All,3D Object",
            "BackgroundColor": "#fbf4ad",
            "Position": [0, -1, 0],
            "Camera": [0, 0, 12],
            "FOV": 13,
        },
        {
            "Id": "35",
            "Name": "Tree with Falling Leaves",
            "Category": "All,Nature",
            "Type": "All,3D Object",
            "BackgroundColor": "#1eb7d1",
            "Position": [0, -1.35, 0],
            "Camera": [0, 0, 12],
            "FOV": 24,
        },
        {
            "Id": "36",
            "Name": "Happy Face",
            "Category": "All,Emoji",
            "Type": "All,3D Object",
            "BackgroundColor": "#bcaaf5",
            "Position": [0, 0, 0],
            "Camera": [0, 0, 12],
            "FOV": 1.5,
        },
        {
            "Id": "37",
            "Name": "Smiling Face",
            "Category": "All,Emoji",
            "Type": "All,3D Object",
            "BackgroundColor": "#ccfccb",
            "Position": [0, 0, 0],
            "Camera": [0, 0, 12],
            "FOV": 1.5,
        },
        {
            "Id": "38",
            "Name": "Kissy Face",
            "Category": "All,Emoji",
            "Type": "All,3D Object",
            "BackgroundColor": "#eda2a2",
            "Position": [0, 0, 0],
            "Camera": [0, 0, 12],
            "FOV": 1.5,
        },
        {
            "Id": "39",
            "Name": "Heart Eyes Face",
            "Category": "All,Emoji",
            "Type": "All,3D Object",
            "BackgroundColor": "#1eb7d1",
            "Position": [0, 0, 0],
            "Camera": [0, 0, 12],
            "FOV": 1.5,
        },
        {
            "Id": "40",
            "Name": "Emoji with Glasses",
            "Category": "All,Emoji",
            "Type": "All,3D Object",
            "BackgroundColor": "#ccfccb",
            "Position": [0, 0, 0],
            "Camera": [0, 0, 12],
            "FOV": 1.5,
        },
        {
            "Id": "41",
            "Name": "Fitness Character",
            "Category": "All,Animation",
            "Type": "All,3D Animation",
            "BackgroundColor": "#bcaaf5",
            "Position": [0, -1, 0],
            "Camera": [0, 0, 12],
            "FOV": 15,
        },
        {
            "Id": "42",
            "Name": "Merlin",
            "Category": "All,Animation",
            "Type": "All,3D Animation",
            "BackgroundColor": "#ccfccb",
            "Position": [0, -3.6, 0],
            "Camera": [0, 0, 12],
            "FOV": 55,
        },
        {
            "Id": "43",
            "Name": "Grooving Cow",
            "Category": "All,Animation",
            "Type": "All,3D Animation",
            "BackgroundColor": "#eda2a2",
            "Position": [0, -3, 0],
            "Camera": [0, 0, 12],
            "FOV": 40,
        },
        {
            "Id": "44",
            "Name": "Bubblegum Dog",
            "Category": "All,Animation",
            "Type": "All,3D Animation",
            "BackgroundColor": "#1eb7d1",
            "Position": [0, -0.3, 0],
            "Camera": [0, 0, 12],
            "FOV": 5,
        },
        {
            "Id": "45",
            "Name": "Dancing Planet",
            "Category": "All,Animation",
            "Type": "All,3D Animation",
            "BackgroundColor": "#63c5c7",
            "Position": [0, -0.5, 0],
            "Camera": [0, 0, 12],
            "FOV": 8,
        },
        {
            "Id": "46",
            "Name": "Skateboarder",
            "Category": "All,Animation",
            "Type": "All,3D Animation",
            "BackgroundColor": "#ffb356",
            "Position": [0, -0.38, 0],
            "Camera": [0, 0, 12],
            "FOV": 5,
        },
        {
            "Id": "47",
            "Name": "Aerobatic Airplane",
            "Category": "All,Animation",
            "Type": "All,3D Animation",
            "BackgroundColor": "#fbf4ad",
            "Position": [0, -1.3, 0],
            "Camera": [0, 0, 12],
            "FOV": 18,
        },
        {
            "Id": "48",
            "Name": "Links",
            "Category": "All,Animation",
            "Type": "All,3D Animation",
            "BackgroundColor": "#1eb7d1",
            "Position": [0.36, -1.6, 0],
            "Camera": [0, 0, 12],
            "FOV": 30,
        },
        {
            "Id": "49",
            "Name": "Photographer Character",
            "Category": "All,Animation",
            "Type": "All,3D Animation",
            "BackgroundColor": "#eda2a2",
            "Position": [0, -0.8, 0],
            "Camera": [0, 0, 12],
            "FOV": 12,
        }
    ];
    return data;
  };

  export default data;