import { Component, useEffect, ChangeEvent } from "react";
import { ethers } from "ethers";
import contract from "../contracts/Project_3D_NFT_Beta.json";

class ContractMetaMaskService {
    async connect() {
        if(!window.ethereum) {
            let installmetamask = JSON.stringify({ "installmetamask": false });
            return installmetamask;
        }
        
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();

        let userAddress = await signer.getAddress();
        let network = await provider.getNetwork();

        if(network.chainId !== 5) {
            let account = JSON.stringify({ "connected": true, "userAddress": userAddress, "chainId": network.chainId, "chainName": network.name });
        
            return account;
        }

        const contractinfo = new ethers.Contract(contract.address, contract.abi, signer);
        let owner = await contractinfo.owner();

        let isOwner = false;
        if(userAddress === owner) {
            isOwner = true;
        }

        let account = JSON.stringify({ "connected": true, "isOwner": isOwner, "userAddress": userAddress, "chainId": network.chainId, "chainName": network.name });
        
        return account;
    }

    async getSalesStatus() {
        let provider = ethers.getDefaultProvider('goerli');
        const contractinfo = new ethers.Contract(contract.address, contract.abi, provider);
        let saleActive = await contractinfo.saleActive();
        let info = JSON.stringify({ "saleActive": saleActive });

        return info;
    }

    async getOwnerOf(id) {
        let provider = ethers.getDefaultProvider('goerli');
        const contractinfo = new ethers.Contract(contract.address, contract.abi, provider);
        
        try {
            let ownerOf = await contractinfo.ownerOf(id);
            let info = JSON.stringify({ "ownerOf": ownerOf });

            return info;
        } catch (e) {
            let error = JSON.stringify({ "error": e });
            return error;
        }

    }

    async mint(id) {
        if(!window.ethereum) {
            let installmetamask = JSON.stringify({ "installmetamask": false });
            return installmetamask;
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();

        let userAddress = await signer.getAddress();
        const contractinfo = new ethers.Contract(contract.address, contract.abi, signer);
        let mintCost = await contractinfo.cost();

        let overrides = {
            value: mintCost
            //value: ethers.utils.parseEther("0.001")
        };
        try {
            const tx = await contractinfo.mint(userAddress, id, overrides);
            let info = JSON.stringify({ "tx": tx });

            return info;
        } catch (e) {
            let error = JSON.stringify({ "error": e });
            return error;
        }
        
    }

    async getAccountCollection() {
        if(!window.ethereum) {
            let installmetamask = JSON.stringify({ "installmetamask": false });
            return installmetamask;
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        
        let userAddress = await signer.getAddress();
        const contractinfo = new ethers.Contract(contract.address, contract.abi, signer);
        let collection = await contractinfo.walletOfOwner(userAddress);
        let info = JSON.stringify({ "collection": collection });

        return info;
    }

    async withdrawAll() {
        if(!window.ethereum) {
            let installmetamask = JSON.stringify({ "installmetamask": false });
            return installmetamask;
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();

        const contractinfo = new ethers.Contract(contract.address, contract.abi, signer);
        const tx = await contractinfo.withdrawAll();
        let info = JSON.stringify({ "tx": tx });

        return info;
    }
}

export default new ContractMetaMaskService();