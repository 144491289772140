import { forwardRef, useImperativeHandle, useEffect, useState, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ReactPaginate from 'react-paginate';
import loadData from "../data/data.js";

const CollectionPagination = forwardRef((props, ref) => {

    const [allData, setAllData] = useState([]);

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    const [isCompleted, setCompleted] = useState(false);

    function Items({ currentItems }) {
        return (
          <>
            {currentItems &&
              currentItems.map((item, index) => (
                <div className="col-sm-12 col-lg-3 mb-lg-3 text-center" key={index}>
                    <Link to={"/collection/" + item['Id']}>
                        <div className="card h-100">
                            <LazyLoadImage
                                alt=""
                                effect="blur"
                                src={process.env.PUBLIC_URL + '/assets/preview-image/' + item['Id'] + '.webp'}
                                className="collection-image w-100 h-100" />
                            <div className="card-body pt-1 pb-1">
                                <p className="collection-id">#{item['Id']}</p>
                                <p className="collection-name">{item['Name']}</p>
                            </div>
                        </div>
                    </Link>
                </div>
              ))}
          </>
        );
    }
    
    const handlePageClick = (event) => {
        const newOffset = (event.selected * props.itemsPerPage) % allData.length;
        console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    function sortId(a, b) {
        if (parseInt(a.Id) < parseInt(b.Id)) {
            return -1;
        }
        if (parseInt(a.Id) > parseInt(b.Id)) {
            return 1;
        }
        return 0;
    }

    function sortIdDesc(a, b) {
        if (parseInt(a.Id) < parseInt(b.Id)) {
            return 1;
        }
        if (parseInt(a.Id) > parseInt(b.Id)) {
            return -1;
        }
        return 0;
    }

    function sortName(a, b) {
        if (a.Name < b.Name) {
            return -1;
        }
        if (a.Name > b.Name) {
            return 1;
        }
        return 0;
    }

    function sortNameDesc(a, b) {
        if (a.Name < b.Name) {
            return 1;
        }
        if (a.Name > b.Name) {
            return -1;
        }
        return 0;
    }

    const loading = async () => {
        const loaddata = await loadData();
        setAllData(loaddata);

        let filterData = loaddata.filter(d => d.Type.includes(props.dataType)).filter(d => d.Category.includes(props.dataCategory));
        const endOffset = itemOffset + props.itemsPerPage;

        if(props.dataSort === "0"){
            filterData = filterData.sort(sortId);
        }
        else if(props.dataSort === "1"){
            filterData = filterData.sort(sortIdDesc);
        }
        else if(props.dataSort === "2"){
            filterData = filterData.sort(sortName);
        }
        else if(props.dataSort === "3"){
            filterData = filterData.sort(sortNameDesc);
        }

        setCurrentItems(filterData.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(filterData.length / props.itemsPerPage));

        setCompleted(true);
        
    };

    useImperativeHandle(ref, () => ({
        resetItemOffset() {
            setItemOffset(0);
        },
    }));

    useEffect(() => {
        loading();
    }, [props.dataType, props.dataCategory, props.dataSort, itemOffset, pageCount]);

    return (
        <>
        {isCompleted
            ? (
                <>
                    <Items currentItems={currentItems} />
                    <ReactPaginate
                        breakLabel="..."
                        previousLabel={<i className="arrow left"></i>}
                        nextLabel={<i className="arrow right"></i>}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        activeClassName={"pagination-active"}
                        previousLinkClassName={"pagination-icon"}
                        nextLinkClassName={"pagination-icon"}
                    />
                </>
            )
            : (
                <Skeleton count={4} />
            )
        }
            
        </>
    );
});

export default CollectionPagination;
